import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'dueDateItem',
    'taskLockItem',
    'goalItem',
    'taskItem',
    'taskList',
    'modulesContainer'
  ];

  connect() {
    // When scrolling to load more modules, overview will update the modules displayed
    // This is necessary to update when loading by scroll backwards
    this.modulesContainerTarget.addEventListener('scrollend', () => {
      this.selectOptionData();
      this.toggleOptionData();
    });

    // This is necessary because when non selected modules are hidden,
    // selectOptionData() needs to check the new modules loaded from the next page
    this._onPagyChange();
  }

  disconnect() {
    this.modulesContainerTarget.removeEventListener('scrollend', () => {
      this.selectOptionData();
      this.toggleOptionData();
    });

    this.observer.disconnect();
  }

  selectOptionData() {
    const modulesTypes = ['event', 'block', 'milestone']
    let selectedElements = document.querySelectorAll('#program-overview-display-modules .js-selected');
    let selectedOptions = [];

    selectedElements.forEach((option) => {
      selectedOptions.push(option.dataset.value.toLowerCase());
    });

    if (selectedOptions.length === 0) {
      this._showModules(modulesTypes);
    } else {
      let typesToHide = modulesTypes.filter(item => !selectedOptions.includes(item));
      this._hideModules(typesToHide);
      this._showModules(selectedOptions);
    }
  }

  toggleOptionData() {
    this.resetOptionData();

    let selectedElement = document.querySelectorAll('#program-overview-toggle-info_menu .js-selected');

    if (selectedElement.length === 0) {
      return;
    }

    let value = selectedElement[0].dataset.value;

    switch (value) {
      case 'all':
        this._toggleAllOptionData();
        break;
      case 'due_date':
        if (this.hasDueDateItemTarget) {
          this.dueDateItemTargets.forEach((item) => {
            item.classList.remove('hidden');
            this._openClosestTaskList(item);
          });
        }
        break;
      case 'task_lock':
        if (this.hasTaskLockItemTarget) {
          this.taskLockItemTargets.forEach((item) => {
            item.classList.remove('hidden');
            this._openClosestTaskList(item);
          });
        }
        break;
      case 'goal':
        if (this.hasGoalItemTarget) {
          this.goalItemTargets.forEach((item) => {
            item.classList.remove('hidden');
            this._openClosestTaskList(item);
          });
        }
        break;
      default:
        break;
    }
  }

  resetOptionData() {
    this._closeAllTaskLists();

    if (this.hasDueDateItemTarget) {
      this.dueDateItemTargets.forEach((item) => {
        item.classList.add('hidden');
      });
    }

    if (this.hasTaskLockItemTarget) {
      this.taskLockItemTargets.forEach((item) => {
        item.classList.add('hidden');
      });
    }

    if (this.hasGoalItemTarget) {
      this.goalItemTargets.forEach((item) => {
        item.classList.add('hidden');
      });
    }
  }

  _toggleAllOptionData() {
    this.dueDateItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      this._openClosestTaskList(item);
    });

    this.taskLockItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      this._openClosestTaskList(item);
    });

    this.goalItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      this._openClosestTaskList(item);
    });

    this.taskItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      this._openClosestTaskList(item);
    });
  }

  _hideModules(typesToHide) {
    let programModules = document.querySelectorAll('.js-overview-module');

    Array.from(programModules).forEach(element => {
      if (typesToHide.includes(element.dataset.moduleType.toLowerCase())) {
        element.classList.add('hidden');
      }
    });
  }

  _showModules(typesToShow) {
    let programModules = document.querySelectorAll('.js-overview-module');

    Array.from(programModules).forEach(element => {
      if (typesToShow.includes(element.dataset.moduleType.toLowerCase())) {
        element.classList.remove('hidden');
      }
    });
  }

  _onPagyChange() {
    let loadProgressTarget = document.getElementById('js-overview-modules-load-progress');

    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Also works on slow internet connections since it
          // waits for the DOM to finish loadings
          setTimeout(() => { this.selectOptionData(); }, 0);
        }
      }
    });

    const config = {
      childList: true
    };

    this.observer.observe(loadProgressTarget, config);
  }

  _openClosestTaskList(item) {
    let closestTaskList = item.closest('.js-overview-task-list-top').querySelector('.js-overview-task-list');

    if (!closestTaskList.classList.contains('open')) {
      closestTaskList.querySelector('.js-overview-task-list-icon').click();
    }

    let container = item.closest('.task-list-item-container');

    if (container) {
      container.classList.add('pt-2');
      container.classList.add('pb-4');
    }
  }

  _closeAllTaskLists() {
    this.taskListTargets.forEach((list) => {
      if (list.classList.contains('open')) {
        list.querySelector('.js-overview-task-list-icon').click();

      }
    });

    let container = document.querySelectorAll('.task-list-item-container');

    container.forEach((item) => {
      item.classList.remove('pt-2');
      item.classList.remove('pb-4');
    });
  }
}
